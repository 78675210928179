import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../../../components/atoms/Seo'
import Navbar from '../../../components/organisms/Navbar'
import StripeTable from '../../../components/molecules/StripeTable'
import ProgramPlaceTemplate from '../../../components/templates/ProgramPlaceTemplate'

const OffreVente = ({ location }) => {
  const title = 'Déposer une annonce de vente - Notre offre'
  const description = 'Notre offre pour déposer une annonce pour la vente de local professionnel'

  return (
    <>
      <SEO
        title={title}
        description={description}>
      </SEO>
      <Navbar location={location} />
      <ProgramPlaceTemplate>
        <StripeTable>
          <stripe-pricing-table
            pricing-table-id="prctbl_1OCgOcAZuoCRZZBsCDbKi4dB"
            publishable-key="pk_live_51NqYCvAZuoCRZZBsPc3XZdkKRz9XrKN2xqX8LJiP3NShS8f7Urm4yETpI9bLna9TFKLBUNi4oPwRD11NV2Fhm18m00nM4oVbJO">
          </stripe-pricing-table>
        </StripeTable>
      </ProgramPlaceTemplate>
    </>
  )
}

OffreVente.propTypes = {
  location: PropTypes.object.isRequired
}

export default OffreVente
